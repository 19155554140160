@charset "UTF-8";

/*! 
Theme Name: FAME 2019
Default Theme - Coding by Florian Sch&uuml;rmann || https://www.45grad.de
*/



/*************************************
	Variables
*************************************/
$font-path 		: '../fonts';
$img-path 		: '../../images';

$blue			: #336699;
$lightblue		: #c2d7eb;
$lighterblue	: #eaeff5;

$red			: #d21621;
$brighred		: #ff0000;

$black 			: #000000;
$grey 			: #666666;


/*************************************
	Fonts
*************************************/

@import "fonts";




/*************************************
	MIXINS
*************************************/

@mixin transition ($time){
	-webkit-transition: all $time ease-in-out 0s;
	-moz-transition: all $time ease-in-out 0s;
	-ms-transition: all $time ease-in-out 0s;
	-o-transition: all $time ease-in-out 0s;
	transition: all $time ease-in-out 0s;
}

@mixin filter ($filter){
	-webkit-filter: $filter;
	-moz-filter: $filter;
	-o-filter: $filter;
	-ms-filter: $filter;
	filter: $filter;
}

@mixin gradient-straight() {
	background: -webkit-linear-gradient(top, #336699 10%,#d21621 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, #336699 10%,#d21621 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, #336699 10%,#d21621 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to top, #336699 10%,#d21621 100%); /* Standard syntax */


    -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#336699, endColorstr=#d21621)";
}

@mixin gradient-long-straight() {
	background: -webkit-linear-gradient(top, #336699 0%, #336699 44%, #873c5a 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, #336699 0%, #336699 44%, #873c5a 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, #336699 0%, #336699 44%, #873c5a 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to top, #336699 0%, #336699 44%, #873c5a 100%); /* Standard syntax */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#336699, endColorstr=#873c5a)";    
}

@mixin gradient-skewed() {
	background: -webkit-linear-gradient(-105deg, #336699 40%,#d21621 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(-105deg, #336699 40%,#d21621 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(-105deg, #336699 40%,#d21621 100%); /* For Firefox 3.6 to 15 */
  
    background-image: linear-gradient(to top, #336699 40%,#d21621 100%); /* Standard syntax */
    background: linear-gradient(195deg, #336699 40%,#d21621 100%); /* Standard syntax */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#336699, endColorstr=#d21621)";    
}

@mixin gradient-bright() {
	background: -webkit-linear-gradient(top, $lightblue 10%, $lighterblue 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, $lightblue 10%, $lighterblue 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, $lightblue 10%, $lighterblue 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to top, $lightblue 10%, $lighterblue 100%); /* Standard syntax */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#c2d7eb, endColorstr=#eaeff5)";    
}

@mixin gradient-bright-reverse() {
	background: -webkit-linear-gradient(top, $lighterblue 10%, $lightblue 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, $lighterblue 10%, $lightblue 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, $lighterblue 10%, $lightblue 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to top, $lighterblue 10%, $lightblue 100%); /* Standard syntax */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#c2d7eb, endColorstr=#eaeff5)";    
}


@mixin hyphens (){
	-webkit-hyphens: auto;
	   -moz-hyphens: auto;
			hyphens: auto;
}

@mixin display-flex {
	display: -ms-flexbox;
	display: -moz-box;
	display: -webkit-box; 
	display: -webkit-flex; 
	display: flex;
}

@mixin flex($var) {
    flex: $var;
    -ms-flex: $var;
    -webkit-flex: $var;
}

@mixin flex-wrap($var) {
    -ms-flex-wrap: $var;
    -moz-flex-wrap: $var;
    -webkit-flex-wrap: $var;
    flex-wrap: $var;
}

@mixin flex-direction($var) {
    -ms-flex-direction: $var;
    -moz-flex-direction: $var;
    -webkit-flex-direction: $var;
    flex-direction: $var;
}

@mixin flex-align($var) {
	-ms-flex-align: $var;
	-moz-box-align: $var;
	-webkit-box-align: $var;
	-webkit-align-items: $var;
	align-items: $var;
}

@mixin flex-calc($var, $margin) {
    -ms-flex: calc(#{$var} - #{$margin});
    -moz-flex: calc(#{$var} - #{$margin});
    -webkit-flex: calc(#{$var} - #{$margin});
    max-width: calc(#{$var} - #{$margin});
}

@mixin rotate($var) {
    -ms-transform: rotate($var);
    -moz-transform: rotate($var);
    -webkit-transform: rotate($var);
    transform: rotate($var);
}

@mixin translate($varX, $varY) {
    -webkit-transform: translate($varX, $varY);
    -moz-transform: translate($varX, $varY);
    -ms-transform: translate($varX, $varY);
    -o-transform: translate($varX, $varY);
    transform: translate($varX, $varY);
}


/*************************************
	GENERAL
*************************************/

@import "general";
@import "layout";
@import "forms";

/*************************************
	AOS
*************************************/

@import "aos/aos";


/*************************************
	HEADER
*************************************/

header.site {

	.flexcol {
		position: relative;
		align-items: flex-end;
		margin: 0;
	}

	.logo {
		margin: 64px 16px 0 -4px;
		float: left;
		max-width: 230px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.title {
		font-family: 'Open Sans', 'Calibri', sans-serif;
		font-weight: 700;
		color: $black;
		line-height: 1.625em;
		padding-bottom: .25em;

		a {
			color: inherit;
			font-weight: inherit;
			line-height: inherit;
			text-decoration: none;
			padding-top: .5em;
		}

		span {
			font-size: 1.5em;
		}

	}

	.login_info {
		font-size: .875em;
		color: $grey;
		text-align: right;
		line-height: 1.5em;
		padding: 0 32px 8px 0;
	}

}

.main_navigation_container {

	ul {

		li {	
			list-style-type: none;
			display: inline-block;
			a {
				color: #ffffff;
				font-family: 'Open Sans', 'Calibri', sans-serif;
				font-size: 1.125em;
				font-weight: 700;
				letter-spacing: 1.12px;
				text-decoration: none;
				padding: 12px 32px;

				&:hover {
					text-decoration: none;
				}
			}

            ul { 
                display: none;
            }			
		}
	}
}

.main_image_row {
	display: block;
	overflow: hidden;
	min-width: 2700px;
	height: 265px;
	border-bottom: 1px solid $grey;

	figure {
		border-right: 1px solid #fff;
		margin-bottom: 4px;
		max-height: 260px;
		float: left;
	}
	img {
		height: 260px;
		width: auto;
	}
}


.content {
	min-height: 800px;

	@include gradient-bright-reverse();
	> .wrapper {
		background-color: #ffffff;
		padding: 40px 64px 40px;
		min-height: 800px;
	}
}

.home .content {
	article {
		padding-top: 24px;
		width: 60.60606060606061%;
		float: left;
	}

	aside {

	}
}

.home aside {
	width: 33.33333333333333%;
	float: right;
}


.box {
	width: 100%;
	border: 2px solid $blue;
	padding: 3px;
	margin-bottom: 32px;

	p {
		font-weight: 400;
		font-size: .875em;
		line-height: 1.5em;
	}

	a {
		text-decoration: none;
		font-weight: 600;
		&:hover {
			text-decoration: underline;
			
		}
	}


	&.news {
		.box_inner {
			padding: 32px 32px 24px;
			@include gradient-bright-reverse();
		}

		img, figure, picture {
			display: none;
		}
		
	}
	&.updates {
		.box_inner {
			padding: 32px;
			@include gradient-skewed();
			img {
				width: 100%;
			}
		}
	}
}





/*************************************
	ROW ELEMENTS
*************************************/

.row {
	display: block;
	overflow: hidden;
	padding: 32px 0 24px;
	border-top: 1px solid #666;

	&:last-of-type {
		border-bottom: 1px solid #666;
	}

	&.image-col {
		> *:first-child {
			width: 256px;
			float: left;
			padding-bottom: 8px;
		}
		> *:last-child {
			width: calc(100% - 288px);
			float: right;
		}
		img {
			display: block;
		}	
	}
}



/*************************************
	FOOTER
*************************************/
.ellipses-wrapper {
    	position: absolute;
    	z-index: 400;
		max-width: 1180px;
		margin: 0 auto 0;
		right: calc(50% - 1180px/2 + 128px);
	
    .ellipses {
    	margin-top: -16px;
    	right: 0;
    	width: 120px;
    	height: auto;
    	content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 137.7 43.7' style='enable-background:new 0 0 137.7 43.7;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;stroke:%23FFFFFF;stroke-width:4;stroke-miterlimit:10;} .st1{stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;} </style> <circle class='st0' cx='68.8' cy='21.8' r='13.5'/> <circle class='st1' cx='22.8' cy='21.8' r='13.5'/> <circle id='Ellipse_2' class='st1' cx='114.8' cy='21.8' r='13.5'/> </svg>");
    }
}

footer.site {
	border-top: 2px solid $black;
	background-color: #fff;
	padding-top: 4px;
	color: #ffffff;
	.footer_inner {
		min-height: 300px;
		display: block;
		overflow: hidden;
		@include gradient-long-straight();
	}

	.wrapper {
		position: relative;
		padding: 40px 64px 40px;
	}

	nav {
		ul {
			li {
				display: block;
				a {
					color: #ffffff;
					line-height: 1.625em;
					text-decoration: none;
					font-weight: 700;
				}
			}
		}
	}

	h4 {
    	font-family: 'Montserrat', Helvetica, sans-serif;
    	font-weight: 600;
    	font-size: 1.25em;
	}

	p, a {
		color: #ffffff;
	}
}


/*Document list */
.document-list {
	margin-top: .75em;
	align-items: flex-start;

	span {
		display: block;
	}

	a {
		text-decoration: none;
		color: inherit;
		align-items: flex-start;
		min-height: 44px;
		&::before {
			content: '';
			display: none;
		}
	}


	> a {
		margin-bottom: 16px;
		display: block;
		font-weight: 400;
		padding-left: 56px;
		line-height: 1.375em;		

		.title {
			font-weight: 600;
			color: $blue;
			align-items: center;
			&.single {
				padding-top: 10px
			}
		}
	}

	.title span {
		display: inline;
	}

	.plain, .txt, .misc {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5l-3-3C9.89,0.18,9.45,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5z M12.4,4.31c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16 c0.1,0.02,0.19,0.07,0.26,0.15L12.4,4.31z M12,17.14H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72c0-0.31,0.26-0.57,0.57-0.57H8v3.71 c0,0.47,0.38,0.86,0.86,0.86h3.71v10.85C12.57,16.89,12.31,17.14,12,17.14z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

	.pdf {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5l-3-3C9.89,0.18,9.45,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5z M12.4,4.31c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16 c0.1,0.02,0.19,0.07,0.26,0.15L12.4,4.31z M12,17.14H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72c0-0.31,0.26-0.57,0.57-0.57H8v3.71 c0,0.47,0.38,0.86,0.86,0.86h3.71v10.85C12.57,16.89,12.31,17.14,12,17.14z M11.21,11.03c-0.48-0.47-1.96-0.33-2.63-0.24 C7.82,10.33,7.32,9.7,6.97,8.77C7.12,8.13,7.4,7.08,7.2,6.45c-0.16-1-1.42-0.88-1.59-0.24C5.43,6.86,5.6,7.79,5.91,8.99 C5.48,10,4.85,11.38,4.4,12.15c-0.74,0.38-1.93,1.05-2.1,1.87c-0.12,0.6,0.82,1.41,1.9,0.23c0.32-0.35,0.69-0.89,1.12-1.62 c0.95-0.31,2-0.71,2.93-0.86c0.78,0.43,1.7,0.71,2.31,0.71C11.54,12.48,11.58,11.4,11.21,11.03L11.21,11.03z M3.03,14.21 c0.21-0.57,1.02-1.23,1.27-1.46C3.51,14.01,3.03,14.23,3.03,14.21z M6.43,6.27c0.31,0,0.28,1.34,0.07,1.7 C6.32,7.39,6.32,6.27,6.43,6.27z M5.41,11.96c0.4-0.71,0.75-1.54,1.03-2.27c0.35,0.63,0.79,1.13,1.25,1.48 C6.83,11.33,6.07,11.7,5.41,11.96z M10.89,11.75c0,0-0.21,0.25-1.55-0.32C10.8,11.32,11.04,11.65,10.89,11.75z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

	.archive {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5l-3-3C9.89,0.18,9.46,0,9,0H1.71C0.77,0,0,0.77,0,1.71v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.25,13.53,3.82,13.21,3.5z M9.14,1.16c0.1,0.02,0.19,0.08,0.27,0.15l3,3 c0.07,0.07,0.12,0.16,0.15,0.27H9.14V1.16z M12.57,16.57c0,0.32-0.26,0.57-0.57,0.57H1.71c-0.32,0-0.57-0.26-0.57-0.57V1.71 c0-0.32,0.26-0.57,0.57-0.57h2.85v1.14H5.7V1.14H8v3.71c0,0.47,0.38,0.86,0.86,0.86h3.71V16.57z M6.86,3.43H5.71V2.29h1.14V3.43z M5.71,3.43v1.14H4.57V3.43H5.71z M5.71,5.71v1.14H4.57V5.71H5.71z M6.86,5.71H5.71V4.57h1.14V5.71z M6.5,9.14H5.71V8H4.57v1.14 l-0.69,3.47c-0.23,1.16,0.65,2.24,1.84,2.24c1.18,0,2.07-1.08,1.84-2.24L6.92,9.49C6.88,9.28,6.71,9.14,6.5,9.14z M5.72,13.93 c-0.64,0-1.16-0.43-1.16-0.97c0-0.53,0.52-0.97,1.16-0.97s1.16,0.43,1.16,0.97C6.88,13.5,6.36,13.93,5.72,13.93z M6.86,8H5.71V6.86 h1.14V8z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}
	
	.ppt {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5l-3-3C9.89,0.18,9.45,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5z M12.4,4.31c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16 c0.1,0.02,0.19,0.07,0.26,0.15L12.4,4.31z M12,17.14H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72c0-0.31,0.26-0.57,0.57-0.57H8v3.71 c0,0.47,0.38,0.86,0.86,0.86h3.71v10.85C12.57,16.89,12.31,17.14,12,17.14z M7.29,7.43H5c-0.24,0-0.43,0.19-0.43,0.43v6.57 c0,0.24,0.19,0.43,0.43,0.43h0.29c0.24,0,0.43-0.19,0.43-0.43v-2.16h1.58c1.38,0,2.42-0.97,2.42-2.46C9.71,8.43,8.86,7.43,7.29,7.43 z M8.22,10.92c-0.27,0.27-0.64,0.41-1.11,0.41h-1.4v-3h1.42c0.46,0,0.82,0.13,1.09,0.4C8.77,9.26,8.76,10.38,8.22,10.92z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

	.word {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5l-3-3C9.89,0.18,9.45,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5z M12.4,4.31c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16 c0.1,0.02,0.19,0.07,0.26,0.15L12.4,4.31z M12,17.14H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72c0-0.31,0.26-0.57,0.57-0.57H8v3.71 c0,0.47,0.38,0.86,0.86,0.86h3.71v10.85C12.57,16.89,12.31,17.14,12,17.14z M10.08,8.34c-0.24,1.17-1.16,4.99-1.19,5.37 c-0.21-1.04-0.02,0.06-1.49-5.39C7.35,8.13,7.18,8,6.98,8H6.76C6.56,8,6.39,8.13,6.34,8.32c-1.37,5.08-1.34,5.02-1.41,5.52 c-0.15-0.85,0.07-0.1-1.23-5.51C3.66,8.14,3.49,8,3.29,8H3.03C2.75,8,2.55,8.26,2.62,8.53c0.34,1.36,1.23,4.91,1.51,6 c0.05,0.19,0.22,0.32,0.41,0.32h0.61c0.19,0,0.36-0.13,0.41-0.32c1.22-4.56,1.2-4.41,1.31-5.1c0.23,1.11,0.01,0.25,1.31,5.1 c0.05,0.19,0.22,0.32,0.41,0.32h0.5c0.2,0,0.49-0.13,0.54-0.32l1.53-6C11.23,8.27,11.03,8,10.75,8H10.5 C10.3,8,10.13,8.14,10.08,8.34L10.08,8.34z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

	.spreadsheet, .xls {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5L10.21,0.5C9.89,0.18,9.46,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5L13.21,3.5z M9.14,1.16c0.1,0.02,0.19,0.07,0.26,0.15l2.99,2.99 c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16z M12.57,16.57c0,0.31-0.26,0.57-0.57,0.57H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72 c0-0.31,0.26-0.57,0.57-0.57H8v3.72c0,0.47,0.38,0.85,0.86,0.85h3.71V16.57z M2.29,7.43v8c0,0.32,0.26,0.57,0.57,0.57h8 c0.32,0,0.57-0.26,0.57-0.57v-8c0-0.32-0.26-0.57-0.57-0.57h-8C2.54,6.86,2.29,7.11,2.29,7.43z M10.28,14.85H7.43v-1.71h2.86V14.85z M10.28,12H7.43v-1.71h2.86V12z M3.43,8h6.86v1.14H3.43V8z M3.43,10.28h2.86V12H3.43V10.28z M3.43,13.14h2.86v1.71H3.43V13.14z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

	.chart {
       	background: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 13.71 18.28' style='enable-background:new 0 0 13.71 18.28;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23FF0000;} </style> <path class='st0' d='M13.21,3.5L10.21,0.5C9.89,0.18,9.46,0,9,0H1.71C0.77,0,0,0.77,0,1.72v14.85c0,0.95,0.77,1.71,1.71,1.71H12 c0.95,0,1.71-0.77,1.71-1.71V4.71C13.71,4.26,13.53,3.82,13.21,3.5L13.21,3.5z M9.14,1.16c0.1,0.02,0.19,0.07,0.26,0.15l2.99,2.99 c0.07,0.07,0.12,0.16,0.15,0.26H9.14V1.16z M12.57,16.57c0,0.31-0.26,0.57-0.57,0.57H1.71c-0.31,0-0.57-0.26-0.57-0.57V1.72 c0-0.31,0.26-0.57,0.57-0.57H8v3.72c0,0.47,0.38,0.85,0.86,0.85h3.71V16.57z M6.86,6.86v4.57h4.57c0,0,0,0,0,0 C11.43,8.9,9.38,6.86,6.86,6.86L6.86,6.86z M8,8.19c0.97,0.34,1.74,1.12,2.09,2.09H8V8.19z M6.28,14.85c-1.58,0-2.86-1.28-2.86-2.86 c0-1.38,0.98-2.53,2.29-2.8V8.06C3.78,8.34,2.29,9.99,2.29,12c0,2.2,1.79,4,4,4c2.01,0,3.66-1.49,3.94-3.43H9.08 C8.82,13.87,7.66,14.85,6.28,14.85z'/> </svg>") left top no-repeat;
		background-size: 32px auto;
	}

}


/*** Image cloud ***/

.images {
	padding: 32px 0;
	figure {
		@include display-flex;
		justify-content: center;
		align-items: center;
		img {
			height: initial;
		}
	}
}



/*************************************
	HELPER
*************************************/

.alignleft {
	float: left;
	padding: 8px 16px 16px 0;
	max-width: 200px;
}

.alignright {
	float: right;
	padding: 8px 0 16px 16px;
	max-width: 200px;
}



@media screen and (min-width: 1180px) {

	// desktop menu styles
	.main_navigation_container {
		border-top: 1px solid $grey;
		padding-top:4px;
		border-bottom: 2px solid #fff;
		margin-top: 16px;

		.main_navigation_container_inner {
			@include gradient-straight();
			.wrapper {

				 > ul {
					> li {
						position: relative;
						float: left;
						&:last-of-type > a {
							border-right: 1px solid #fff;
						}
						> a {
							display: inline-block;
							border-left: 1px solid #fff;
							@include transition(0.2s);
							&:hover {
								background-color: $blue;
								@include transition(0.2s);
							}

						}
						&:hover {
							ul {
								padding-top: 2px;
								position: absolute;
								display: block;
								width: auto;
								min-width: 212px;

								li {
									background-color: $red;
									width: 100%;
									a {
										font-size: 0.875em;
										font-weight: 600;
										padding: 6px 34px 6px 34px;
										display: block;
										border-bottom: 1px solid #fff;
									}
									&:hover a {
										color: $blue;
										background-color: #fff;
									}
								}
							}
						}
					}
				}
			}
		}
	}

}


@media screen and (max-width: 1180px) {

	
	header.site {
		.main_image_row {
			margin-top: 16px;
		}
		.logo {
			margin-left: 62px;
		}
	}

	.content {
		background: transparent;
		min-height: 0;
	}

	// mobile styles
    .main_navigation_container {
        display: inline;
        position: fixed;
        z-index: 2000;
        left: 0;
        top: 0;
        height: 100%;
        max-width: 400px;
        width: calc(100% - 134px);
        @include translate(-400px, 0);
        @include transition (0.2s);

        &.active {
            @include translate(0, 0);
            @include transition (0.2s);
        }

        .main_navigation_container_inner {
        	height: 100%;		
        	.wrapper {
        		height: 100%;
		        > ul {
		            background-color: $blue;
		            height: 100%;
		            width: 100%;

		            >li {
		                width: 100%;
		                border-bottom: 1px solid #fff;

		                &.rex-active a {
		                	font-weight: 800;
		                }

		                a {
		                    color: #fff;
		                    width: 100%;
		                    display: inline-block;
		                    padding: 16px 16px;
		                }
		                ul li {
		                	width: 100%;
		                	border-bottom: 1px solid #fff;
		                	background-color: $red;
		                	a {
		                		padding: 8px 16px;
		                		font-weight: 400;
		                	}
		                	
		                	&:first-of-type {
		                		border-top: 1px solid #fff;
		                	}                	
		                	&:last-of-type {
		                		border: 0;
		                	}

		                }
		            }
		        }
        	}
        }

        +.toggle {
        	@include gradient-straight;
        	cursor: pointer;
        	padding: 8px;
            display: inline-block;
            position: absolute;
            top: 16px;
            right: 16px;
            text-align: center;
            width: 50px;
            z-index: 800;
            content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 70 48' style='enable-background:new 0 0 70 48;' xml:space='preserve'> <style type='text/css'> .menubar{fill:%23ffffff;} </style> <rect class='menubar' width='70' height='8'/> <rect class='menubar' y='40' width='70' height='8'/> <rect class='menubar' y='20' width='70' height='8'/> </svg>");
			&.active {
				content: url("data:image/svg+xml; utf8, <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 70 48' style='enable-background:new 0 0 70 48;' xml:space='preserve'> <style type='text/css'> .st0{fill:%23ffffff;} </style> <path class='st0' d='M41.7,24L57.5,8.2c0.9-0.9,0.9-2.4,0-3.3l-3.7-3.7c-0.9-0.9-2.4-0.9-3.3,0L34.7,17L18.9,1.2 c-0.9-0.9-2.4-0.9-3.3,0l-3.7,3.7c-0.9,0.9-0.9,2.4,0,3.3L27.7,24L11.9,39.8c-0.9,0.9-0.9,2.4,0,3.3l3.7,3.7c0.9,0.9,2.4,0.9,3.3,0 L34.7,31l15.8,15.8c0.9,0.9,2.4,0.9,3.3,0l3.7-3.7c0.9-0.9,0.9-2.4,0-3.3L41.7,24z'/> </svg>");
			}            
        }
    }

	.box.news .box_inner, 
	.box.updates .box_inner {
		padding-left: 16px;
		padding-right: 16px;
		img {
			display: none;
		}
	}


}	


@media screen and (max-width: 768px) {

	html, body {
		-webkit-overflow-scrolling: touch;
	}

	header.site{
		height: auto;
		.logo {
    		margin-left: 0;
    	}

		.flexcol {
			padding: 0 16px;		
		}
		.login_info {
			display: none;
		}

	}


	.content>.wrapper {
		padding: 16px 16px 40px;
		min-height: 0;
		article {
			width: 100%;
		}
		aside {
			width: 100%;
		}
	}

	.row.person {
		&.image-col>:last-child {
			padding-top: 16px;
			width: 100%;
		}
	}




	footer {
		.flexcol {
			> * {
				max-width: none!important;
				width: 100%;
				text-align: center;
			}
			a {
				padding: 6px 0;
				display: inline-block;
			}
			.btn {
				float: none;
				padding: 4px 12px;
				
			}
		}
	}
	.text img {
		&.img-fame-right,
		&.img-fame-left, {
			width: auto;
			// padding: 0 0 16px 0;
		}	
	}

}


@media screen and (max-width: 680px) {

	header.site{
		padding-top: 16px;
		.logo {
			margin: 0;
		}
		.flexcol {

			display: inline-block;

			> * {
				width: 100%;
				float: left;
				margin-left: 0;
			}

			.title {
				margin: 16px 0 ;
			}
		}

		.main_image_row {
			height: 16px;
			margin: 0;
			@include gradient-straight();
			figure {
				display: none;
			}
		}
	}	

	figure.image {
		width: 100%;
		padding: 16px 0;
		img {
			margin: 0 auto 16px!important;
			padding: 0!important;
			float: none!important;
			display: block;


		}
	}

	.text img + figcaption {
		display: block;
		width: 100%;
		margin: auto;
		text-align: center!important;
		float: none!important;
		max-width: 100%;
	}

}