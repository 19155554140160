/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../../fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../../fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/montserrat-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
       url('../../fonts/montserrat-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v12-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../../fonts/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('../../fonts/montserrat-v12-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
       url('../../fonts/montserrat-v12-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v12-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v12-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v12-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v12-latin-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../../fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
