.wrapper{
	width: 100%;
	max-width: 1180px;
	margin: 0 auto;

	&:after{
		clear: both;
		display: block;
		content:'';
	}
}

article > section {
	// padding: 0 0 32px 0;
}


.flexcol {
	@include display-flex;
	@include flex-direction(row); 
	@include flex-wrap(wrap);
	align-content: center;
	width: calc(100% + 32px);
	margin: 0 -16px;


	> *{
		margin: 0 16px;
		@include flex(1 0 0px);
		max-width: 100%;
		min-width: 0%; // IE 11 overflow fix
	}

	&.two > * {
		@include flex(0 0 calc(50% - 32px));
		@include flex-calc(50%, 32px);
		flex-basis: 100%; // neccessary for IE, otherwise it won't wrap
		
	}

	&.three > * {
		@include flex(0 0 calc(33.33333332% - 32px));
		@include flex-calc(33.33333332%, 32px);
		flex-basis: 100%; 
		// align-self: stretch;
	}

	&.four > * {
		@include flex(0 0 calc(25% - 32px));
		@include flex-calc(25%, 32px);
		flex-basis: 100%;
		// align-self: stretch;
	}

	&.justify-center {
		justify-content: center;
	}

	&.flex-start {
		align-items: flex-start;
	}
}




.columns {	
	> * {
		width: 100%;
		-webkit-column-break-inside: avoid;
        page-break-inside: avoid;
		break-inside: avoid;		
	}

	&.two {
    	column-count: 2;
    	column-gap: 32px;
	}

	&.three {
    	column-count: 3;
    	column-gap: 32px;
	}
}


@media screen and (max-width: 1200px) {
	.wrapper .wrapper {
		width: calc(100% - 32px);
		margin-left: 16px;
		margin-right: 16px;
	}
	
}


@media screen and (max-width: 768px) {
	.flexcol.two>* {
		@include flex(0 0 100%);
		@include flex-calc(100%, 0px);
		margin-bottom: 32px;
	}
	.flexcol.three>* {
		@include flex(0 0 calc(50% - 32px));
		@include flex-calc(50%, 32px);
		margin-bottom: 32px;
	}

	.columns {	
		&.two {
	    	column-count: 1;
		}	
	}
	
}

@media screen and (max-width: 600px) {
	.flex-col.three>* {
		@include flex(0 0 100%);
		@include flex-calc(100%, 0px);

	}

}