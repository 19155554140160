form {
    box-sizing: content-box;

    margin: 0 auto;

    .row {
        position: relative;
        width: auto;
        border: 0;
        display: block;
        clear: both;
        overflow: hidden;
        padding: .375em 0;
    }

    input,
    label,
    select,
    textarea,
    button {
        border: 0;
        display: inline-block;

    }

    label {
        padding-top: 0;
        padding-right: 24px;
        padding-left: 4px;

    }

    input:focus,
    select:focus {
        outline: 0;
    }


    input[type=reset],
    input[type=checkbox],
    input[type=radio],
    select {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }


    input[type=date],
    input[type=datetime],
    input[type=datetime-local],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=range],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week] {
        width: 100%;
        background-color: #ffffff;
        padding: 8px 8px 8px;
        border: 1px solid $grey;
        max-width: 400px;
    }




    input[type=checkbox],
    input[type=radio] {
        width: 13px;
        height: 13px;
    }


    input[type=submit] {
        &:hover {
            // background: $blue;
            background-color: $blue;
        }

    }

    input[type=file] {}


    input[type=search] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
    }


    ::-webkit-search-decoration {
        display: none;
    }


    ::-webkit-file-upload-button {
        padding: 0;
        border: 0;
        background: none;
    }

    textarea {
        vertical-align: top;
        overflow: auto;
    }


    //** SELECT BOXES **//

    .select-wrapper {
        position: relative;
        display: block;
        height: 50px;
        line-height: 3;
        overflow: hidden;
    }

    .select-wrapper::after {
        content: '\25BC';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0 1em;
        background: transparent;
        pointer-events: none;
    }

    .select-wrapper:hover::after {
        color: $blue;
    }

    .select-wrapper::after {
        @include transition(0.2s)
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        min-height: 50px;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 0 0 12px;
        color: $black;
        background-color: #ffffff;
        cursor: pointer;
    }

    select::-ms-expand {
        display: none;
    }

    select[multiple] {
        vertical-align: top;
    }

    .input-group:last-of-type {
        margin-bottom: 16px;
    }

}

footer.site {
    form {
        input[type=submit], button, .btn {
            cursor: pointer;
            font-size: .875em;
            float: right;
            // border: 2px solid #fff;
        }

        label {
            padding-top: 0;
            color: #ffffff;
            width: 100%;
        }

        .btn, button {
            float: left;
            margin-top: 0;
            // border: 2px solid white;
        }

        .btn_wrapper {
            margin: 0;
        }

        input {
            // margin-bottom: 8px;
            &:last-of-type {
                // margin-bottom: 16px;
            }
        }
    }      
}

.msg, .rex-yform .alert {
    display: block;
    padding: .5em .5em .5em 1em;
    font-weight: 400;
    width: 100%;

    &.success {
        background-color: #cbe8ae;
    }

    &.error, &.alert-danger {
        background-color: #e7aeae;
    }

    &.info {
        background-color: $lightblue;
    }
}







@media screen and (max-width: 768px) {
    .flex-col.two>* {
        @include flex(0 0 100%);
        @include flex-calc(100%, 0px);
        margin-bottom: 32px;
    }

}